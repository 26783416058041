import type { LinkProps as RouterLinkProps } from '@remix-run/react';
import { Link as RouterLink } from '@remix-run/react';
import cx from 'classnames';

import type { ButtonVariant as ButtonComponentVariant } from './Button';
import { getButtonClassNames } from './Button';
import { Icon, type IconProps } from './Icon';

type ButtonVariant =
  | 'btn-primary-fill'
  | 'btn-primary-outline'
  | 'btn-primary-secondary'
  | 'btn-registration-outline'
  | 'btn-registration-yellow'
  | 'legacy-btn-primary'
  | 'legacy-btn-ghost'
  | 'legacy-btn-link'
  | 'legacy-btn-neutral'
  | 'legacy-btn-muted';
type LinkVariant = 'legacy-primary' | 'legacy-neutral' | 'legacy-inherit';
export type Variant = LinkVariant | ButtonVariant;
type LinkButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type LinkShape = 'none' | 'square' | 'circle';

export interface LinkProps extends RouterLinkProps {
  variant?: Variant;
  size?: LinkButtonSize;
  shape?: LinkShape;
  icon?: IconProps['name'];
}

export function Link({
  to,
  variant = 'legacy-primary',
  size = 'md',
  shape = 'none',
  icon,
  className,
  children,
  ...rest
}: LinkProps) {
  const isLegacyVariant = variant.startsWith('legacy-');
  const buttonVariant = buttonVariantByLinkVariant[variant];

  return (
    <RouterLink
      to={to}
      className={cx(
        className,
        classesByVariant[variant],
        buttonVariant ? getButtonClassNames({ variant: buttonVariant, size, shape, isLegacy: isLegacyVariant }) : [],
      )}
      {...rest}
    >
      {icon ? <Icon name={icon} className={buttonVariant ? '' : 'mr-2'} /> : null}
      {children}
    </RouterLink>
  );
}

const classesByVariant: Record<Variant, string | string[]> = {
  'btn-primary-fill': '',
  'btn-primary-outline': '',
  'btn-primary-secondary': '',
  'btn-registration-outline': '',
  'btn-registration-yellow': '',
  // Legacy
  'legacy-primary': 'link link-hover link-primary',
  'legacy-neutral': 'link link-hover link-neutral',
  'legacy-inherit': 'link link-hover',
  'legacy-btn-primary': '',
  'legacy-btn-ghost': '',
  'legacy-btn-link': '',
  'legacy-btn-neutral': '',
  'legacy-btn-muted': '',
};

const buttonVariantByLinkVariant: Partial<Record<Variant, ButtonComponentVariant>> = {
  'btn-primary-fill': 'primary-fill',
  'btn-primary-outline': 'primary-outline',
  'btn-primary-secondary': 'primary-secondary',
  'btn-registration-yellow': 'registration-yellow',
  'btn-registration-outline': 'registration-outline',
  'legacy-btn-primary': 'legacy-primary',
  'legacy-btn-ghost': 'legacy-ghost',
  'legacy-btn-link': 'legacy-link',
  'legacy-btn-neutral': 'legacy-neutral',
  'legacy-btn-muted': 'legacy-muted',
};
